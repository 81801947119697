import { useAuth } from "@clerk/clerk-react"
import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

// React Components
import { Container, Row, Col, Button, Table, Dropdown } from "react-bootstrap"
import { getProjects } from "../utils/apiRequests"
import NewProjectsModal from "./NewProjectModal";
import RenameProjectModal from "./RenameProjectModal";
import DateTimeFormatter from "./DateTimeFormatter";
import CopyToClipboardButton from './CopyToClipboardButton';
import ProjectContext from '../contexts/ProjectContext';

// CSS imports
import "./SettingsProjects.css"


function SettingsProjects() {
  const { getToken } = useAuth()
  const [projects, setProjects] = useState([])
  const [newProjectModal, setNewProjectModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null) // {"name": "", "id": ""}

  /**
   *
   */
  async function getProjectsData() {
    const token = await getToken({ template: 'protean-default' })
    const { data } = await getProjects(token)
    if (data) {
      setProjects([...data])
    }
  }

  // Inital component mount fetch projects data
  useEffect(() => {
    getProjectsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   *
   */
  return (
    <Container className="settings-projects">

      <NewProjectsModal
        show={newProjectModal}
        onHide={() => {
          setNewProjectModal(false)
          getProjectsData()
        }}
      />

      <ProjectContext.Provider value={{ selectedProject }}>
      <RenameProjectModal
        show={showRenameModal}
        onHide={() => {
          setShowRenameModal(false)
          setSelectedProject(null)
          getProjectsData()
        }}
      />
      </ProjectContext.Provider>

      <Row>
        <Col>
          <h3>Projects</h3>
        </Col>
        <Col>
          <Button
            variant="outline-dark"
            className="create-new-button rounded-1"
            onClick={() => { setNewProjectModal(true) }}
          >New Project
          </Button>
        </Col>
      </Row>

      <Table className="projects-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>ID</th>
          <th>Ecosystem</th>
          <th>Org</th>
          <th>Role</th>
          <th>Created</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        {
          projects.length > 0 ?
            projects.map(p => {
              return (
              <tr key={p.projects_id}>
                <td>{p.projects_name}</td>
                <td><pre>{p.projects_id}<CopyToClipboardButton data={p.projects_id} /></pre></td>
                <td>{p.projects_ecosystem}</td>
                <td>{p.organization_name}</td>
                <td>{p.role}</td>
                <td><pre><DateTimeFormatter dateTimeString={p.created_at} /></pre></td>
                <td>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" size="sm">
                            <FontAwesomeIcon icon={ faEllipsis } />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                            disabled={p.role !== "admin"}
                            onClick={() => {
                                setSelectedProject({"name": p.projects_name, "id": p.projects_id})
                                setShowRenameModal(true)
                            }}>
                                Rename {(p.role === "admin") ? p.projects_name : `${p.projects_name} (Admin Only)`  }
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
              </tr>
              )
            })
          :
            <tr>
              <td style={{paddingBottom: '20px'}}>No Projects (Yet!)</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
        }
        </tbody>
      </Table>

    </Container>
  )
}

export default SettingsProjects
