// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'IBM-Plex-Mono',*/
  /*  sans-serif;*/
  /* font-family: Raleway, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* invert logo color in footer to make it white */
footer img {
  filter: invert(1);
}

a.protean-link {
    color: black;
    text-decoration-style: dashed;
}

a.protean-link:hover {
    color: black;
    text-decoration-style: solid;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,kFAAkF;EAClF,yFAAyF;EACzF,gBAAgB;EAChB,sCAAsC;EACtC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,iDAAiD;AACjD;EAEE,iBAAiB;AACnB;;AAEA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,4BAA4B;AAChC","sourcesContent":["body {\n  margin: 0;\n  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/\n  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'IBM-Plex-Mono',*/\n  /*  sans-serif;*/\n  /* font-family: Raleway, sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n/* invert logo color in footer to make it white */\nfooter img {\n  -webkit-filter: invert(1);\n  filter: invert(1);\n}\n\na.protean-link {\n    color: black;\n    text-decoration-style: dashed;\n}\n\na.protean-link:hover {\n    color: black;\n    text-decoration-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
