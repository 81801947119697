import { useAuth } from "@clerk/clerk-react";
import { useState } from "react";
import {
  Modal
} from "react-bootstrap"
import { createCheckoutSession } from "../utils/apiRequests"
import APIErrorModal from "./APIErrorModal";

// stripe
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


function StripeCheckoutModal(props) {
    const [errorModalShow, setErrorModalShow] = useState(false)
    const [errorModalMsg, setErrorModalMsg] = useState('')
    const selectedOrganizationId = props.organizationId;

    const { getToken } = useAuth()

    // Get stripe checkout session
    async function fetchClientSecret() {
        try {
            let token = await getToken({ template: 'protean-default' });
            let { data, error } = await createCheckoutSession(token, selectedOrganizationId, props.billingTier);

            if (error != null || data == null || data.clientSecret == null) {
                throw new Error(error?.message || 'Could not fetch Checkout data');
            }

            return data.clientSecret;
        } catch (error) {
            setErrorModalMsg(error.message);
            setErrorModalShow(true);
            throw error; // Re-throw the error so Stripe knows the fetch failed
        }
    }

    const opts = { fetchClientSecret }

  return (
    <>
      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

      <Modal
          { ...props }
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
        <Modal.Header closeButton>
            <Modal.Title>
                Checkout
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={opts}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StripeCheckoutModal;
