import { Link } from 'react-router-dom'
import {
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Stack,
  Container, Row, Col
} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  SignUpButton,
  UserButton,
} from "@clerk/clerk-react";
import ProteanLabsLogo from "./Logo";
import NavBarDropdownButton from './NavBarDropdownButton';

import "./NavBar.css"


function ProteanNavBar() {
  const docsUrl = process.env.REACT_APP_PROTEAN_DOCS_URL;
  const blogUrl = process.env.REACT_APP_PROTEAN_BLOG_URL;

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="white"
      sticky="top"
      className="protean-navbar shadow-none border-bottom"
      variant='tabs'
    >
      <Container>

        <Navbar.Brand>
          <Link to="/" style={{color: 'black', textDecoration: 'none'}}>
            <Row>
              <Col style={{paddingRight: '0px'}}>
                <ProteanLabsLogo size='40' />
              </Col>
              <Col style={{paddingTop: '5px', paddingLeft: '10px', paddingRight: '20px'}}>
                <span>Protean Labs</span>
              </Col>
            </Row>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav className='me-auto'>
            <NavDropdown title="Products" id="collapsible-nav-dropdown">
              <LinkContainer to="/products/software-composition-analysis" activeStyle={{backgroundColor: 'white'}}>
                <NavDropdown.Item>
                  <NavBarDropdownButton name='Software Composition Analysis' />
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            <a href="/pricing" rel="noreferrer noopener" id="docs-link">Pricing</a>

            <a href={docsUrl} rel="noreferrer noopener" id="docs-link">Docs</a>

            <a href={blogUrl} rel="noreferrer noopener" id="blog-link">Blog</a>
          </Nav>

          <SignedOut>
            <Nav>
              {
              ('REACT_APP_COMING_SOON' in process.env) ?
                <Button
                  style={{borderColor: 'black'}}
                  deactivated
                  variant='light'
                >
                  Coming Soon
                </Button>
              :
                <Stack direction="horizontal" gap={1}>
                  <SignUpButton>
                   <Button
                    style={{
                      background: 'black',
                      borderColor: 'black'
                    }}
                    >
                    Sign up for free
                   </Button>
                  </SignUpButton>
                  <SignInButton>
                    <Button
                    variant="outline"
                    style={{
                      borderColor: 'black'
                    }}
                  >
                    Log In
                    </Button>
                  </SignInButton>
                </Stack>
              }
            </Nav>
          </SignedOut>

          <SignedIn>
            <Nav className='me-auto'>
            </Nav>
            <Nav>
              <LinkContainer to="/dashboard">
                <Nav.Link>
                  <Button variant="outline-light" title='Dashboard' id='dashboard-button'>
                    <FontAwesomeIcon
                      icon={faLaptopCode}
                      className='nav-icon'
                      style={{
                        color: '#9a5df5',
                      }}
                    />
                  </Button>
                </Nav.Link>
              </LinkContainer>
              <Button variant="link">
                <UserButton style={{ marginTop: '10px' }} />
              </Button>
            </Nav>
          </SignedIn>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default ProteanNavBar;
