import React from "react";
import { Container, Row, CardGroup, Card, Accordion } from "react-bootstrap";

function Pricing() {
  return (
    <Container
      style={{
        minHeight: "90vh",
        paddingTop: "20px",
        paddingLeft: "auto",
        paddingRight: "auto",
      }}
    >

      <Row>
        <h1
          style={{
            width: "auto",
            margin: "auto",
            paddingTop: "50px",
          }}
        >
          Pricing and Plans
        </h1>
      </Row>
      <Row>
        <h2
          style={{
            width: "auto",
            margin: "auto",
            paddingTop: "50px",
            paddingBottom: "90px",
          }}
        >
          Reliable, Affordable Security for Everyone
        </h2>

        <CardGroup>
          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Founder</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 1 Seat <br />
                ✅ Up to 1 Project <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>Free</center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Startup</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 5 Seats <br />
                ✅ Up to 3 Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Email Support <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  $50/<small className="text-muted">m</small>
                </center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Business</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Up to 50 Seats <br />
                ✅ Up to 30 Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Extended Data Retention <br />
                ✅ Email Support <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  $400/<small className="text-muted">m</small>
                </center>
              </h5>
            </Card.Footer>
          </Card>

          <Card border="dark">
            <Card.Body>
              <Card.Title>
                <center>
                  <pre>Enterprise</pre>
                </center>
              </Card.Title>
              <Card.Text>
                ✅ Unlimited Seats <br />
                ✅ Unlimited Projects <br />
                ✅ Unlimited Vulnerability Scans per project <br />
                ✅ Vulnerability alerting <br />
                ✅ Dashboard access <br />
                ✅ Extended Data Retention <br />
                ✅ Email Support <br />
                ✅ Priority Support Resolution <br />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <h5>
                <center>
                  $2500/<small className="text-muted">m</small>
                </center>
              </h5>
            </Card.Footer>
          </Card>
        </CardGroup>
      </Row>
      <Row>
        <h2
          style={{
            width: "auto",
            margin: "auto",
            paddingTop: "60px",
            paddingBottom: "60px",
            textAlign: "center"
          }}
        >
          Frequently Asked Questions
        </h2>

        <Accordion style={{
          maxWidth: "75%",
          margin: "auto",
          marginBottom: "60px"
        }}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Why is Software Composition Analysis (SCA) Important?</Accordion.Header>
            <Accordion.Body>
              Modern software relies heavily on open-source components, which can introduce vulnerabilities, licensing risks, and supply chain threats. SCA provides visibility into every third-party library, framework, and dependency in your codebase, identifying critical vulnerabilities (e.g., CVEs), outdated packages, and license compliance issues. By proactively managing these risks, organizations avoid costly breaches, legal disputes, and operational delays, ensuring both security and compliance.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>How Is My Payment Information Secured?</Accordion.Header>
            <Accordion.Body>
              We do not store or process payment details. All transactions are handled by Stripe, a PCI-DSS Level 1 certified provider. Stripe employs AES-256 encryption, tokenization, and rigorous security protocols to protect financial data. This ensures compliance with global standards while eliminating payment-related risks from our systems.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>What is Shift-Left Security?</Accordion.Header>
            <Accordion.Body>
              Shift-left security integrates vulnerability detection and remediation into the earliest stages of the software development lifecycle (SDLC). Instead of addressing risks post-production, developers identify and resolve issues during coding or CI/CD pipeline execution. This approach reduces remediation costs, accelerates release cycles, and fosters a culture of security ownership within engineering teams.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Why is Shift-Left Security Critical?</Accordion.Header>
            <Accordion.Body>
              Research shows vulnerabilities discovered post-production can cost 10-100x more to remediate than those resolved during development. Shift-left minimizes downtime, prevents security debt accumulation, and aligns with DevOps practices by embedding security into automated workflows. It also reduces the likelihood of breaches stemming from preventable coding errors or outdated dependencies.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>How Does SCA Integrate with Development Workflows?</Accordion.Header>
            <Accordion.Body>
              Our SCA solution integrates natively with CI/CD tools (e.g., Jenkins, GitHub Actions) and IDEs, scanning dependencies in real time during code commits, pull requests, or pipeline runs. Results are prioritized by severity and delivered directly to developers, enabling immediate remediation without disrupting workflows. Policy enforcement ensures compliance thresholds are met before code progresses to production.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>What Risks Do Open-Source Components Pose?</Accordion.Header>
            <Accordion.Body>
              Open-source components often contain unpatched vulnerabilities, restrictive licenses (e.g., GPL), or malicious code introduced via compromised repositories. For example, a single outdated library could expose your application to exploits like Log4Shell. SCA mitigates these risks by providing an inventory of dependencies, monitoring for new vulnerabilities, and flagging license conflicts that could impact intellectual property.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>How Does SCA Support Compliance (GDPR, PCI-DSS)?</Accordion.Header>
            <Accordion.Body>
              SCA tools automate compliance by identifying components that violate data privacy regulations (e.g., libraries logging sensitive user data) or use restrictive licenses requiring proprietary code disclosure. Custom policies block non-compliant dependencies during builds, while audit-ready reports document remediation efforts, license usage, and vulnerability histories—simplifying compliance for frameworks like GDPR, HIPAA, or PCI-DSS.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>How Do SCA, SAST, and DAST Differ?</Accordion.Header>
            <Accordion.Body>
              SCA: Secures third-party/open-source components.<br/><br/>
              SAST: Analyzes proprietary source code for vulnerabilities during development.<br/><br/>
              DAST: Tests running applications for runtime exploits (e.g., API flaws).<br/><br/>
              While SAST and DAST focus on internally developed code, SCA addresses the growing risk of supply chain attacks, which account for 60% of breaches (Synopsys, 2023). Together, they provide end-to-end coverage.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>What Remediation Steps Follow a Critical Vulnerability Detection?</Accordion.Header>
            <Accordion.Body>
              Critical vulnerabilities trigger prioritized alerts with actionable guidance, such as patching versions, workarounds, or alternative libraries. Impact analysis maps affected services and dependencies, enabling swift resolution. Integration with ticketing systems (e.g., Jira) streamlines collaboration between security and development teams, ensuring minimal disruption to operations.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </Container>
  );
}

export default Pricing;
