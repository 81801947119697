import React from "react";

function CookiePolicy() {
  return (
    <div
      style={{
        textAlign: "left",
        paddingTop: "100px",
        paddingBottom: "100px",
        paddingLeft: "20%",
        paddingRight: "20%",
      }}
    >
      <h1>Cookie Policy</h1>
      <sub>
        Last updated: <b>Jan 2025</b>
      </sub>
      <br />
      <br />
      <br />

      <p>
        Protean Labs's website does not automatically collect personally
        identifiable information. We do track certain information about the
        visits to our website. We compile traffic-based statistics that show the
        numbers and frequency of visitors to our website and its individual
        pages. This is an aggregated statistical report which is used internally
        to better understand our website traffic. The statistics contain no
        personal information and cannot be used to gather such information.
      </p>

      <p>
        Protean Labs does collect and track user-submitted information, such as
        through our contact forms and employment application forms. Protean Labs
        does not use use information other than for the intended purpose of
        contacting the user submitting the personally identifiable information.
      </p>

      <p>
        Protean Labs undertakes no obligation to users for third party links or
        verification services, which will be governed by their own respective
        privacy policies and terms.
      </p>

      <br />
    </div>
  );
}

export default CookiePolicy;
