// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-new-button {
  float: right;
}

.projects-table tr td {
  font-weight: normal;
  padding-top: 20px;
}

/* Dropdown arrow hide */
.dropdown-toggle::after {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsProjects.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA,wBAAwB;AACxB;EACE,aAAa;AACf","sourcesContent":[".create-new-button {\n  float: right;\n}\n\n.projects-table tr td {\n  font-weight: normal;\n  padding-top: 20px;\n}\n\n/* Dropdown arrow hide */\n.dropdown-toggle::after {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
