// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protean-navbar {
  box-shadow: 0 2px 30px rgba(0,0,0,.08)
}

.dropdown-menu.active {
  --bs-dropdown-link-active-bg: white;
}

.nav-icon {
  vertical-align: middle;
  height: 20px;
  padding: 0;
}

#collapsible-nav-dropdown {
  color: black;
}

#blog-link, #docs-link, #pricing-link {
  text-decoration: none;
  color: black;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.3s ease;
}

#blog-link:hover, #docs-link:hover, #pricing-link {
  color: #9a5df5;
  text-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
  transform: translateY(-2px);
}

#blog-link:active, #docs-link:active, #pricing-link:active {
    transform: translateY(1px);
}

/* #dashboard-button */

#dashboard-button {
    border: 1px solid #dee2e6;
    background: transparent;
    padding: 8px 16px;
    transition: all 0.3s ease;
    border-radius: 8px;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

#dashboard-button:hover {
    background: linear-gradient(145deg, #ffffff, #f5f5f5);
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

#dashboard-button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,4CAA4C;EAC5C,2BAA2B;AAC7B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,sBAAsB;;AAEtB;IACI,yBAAyB;IACzB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,yCAAyC;AAC7C;;AAEA;IACI,qDAAqD;IACrD,2BAA2B;IAC3B,0CAA0C;AAC9C;;AAEA;IACI,0BAA0B;IAC1B,yCAAyC;AAC7C","sourcesContent":[".protean-navbar {\n  box-shadow: 0 2px 30px rgba(0,0,0,.08)\n}\n\n.dropdown-menu.active {\n  --bs-dropdown-link-active-bg: white;\n}\n\n.nav-icon {\n  vertical-align: middle;\n  height: 20px;\n  padding: 0;\n}\n\n#collapsible-nav-dropdown {\n  color: black;\n}\n\n#blog-link, #docs-link, #pricing-link {\n  text-decoration: none;\n  color: black;\n  padding-top: 8px;\n  padding-left: 5px;\n  padding-right: 5px;\n  transition: all 0.3s ease;\n}\n\n#blog-link:hover, #docs-link:hover, #pricing-link {\n  color: #9a5df5;\n  text-shadow: 0 0 8px rgba(52, 152, 219, 0.3);\n  transform: translateY(-2px);\n}\n\n#blog-link:active, #docs-link:active, #pricing-link:active {\n    transform: translateY(1px);\n}\n\n/* #dashboard-button */\n\n#dashboard-button {\n    border: 1px solid #dee2e6;\n    background: transparent;\n    padding: 8px 16px;\n    transition: all 0.3s ease;\n    border-radius: 8px;\n    backdrop-filter: blur(5px);\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);\n}\n\n#dashboard-button:hover {\n    background: linear-gradient(145deg, #ffffff, #f5f5f5);\n    transform: translateY(-2px);\n    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);\n}\n\n#dashboard-button:active {\n    transform: translateY(1px);\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
