import React, { useEffect, useState } from "react";
import { Card, Container, Row, Table, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getOrganizationDetails,
  sendOrganizationInvite,
} from "../utils/apiRequests";
import { useAuth } from "@clerk/clerk-react";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

function OrganizationDetail() {
  // Auth token
  const { getToken } = useAuth();

  // Get the organizationId param from the URL.
  let { organizationId } = useParams();

  const [org, setOrg] = useState({});
  const [owner, setOwner] = useState({});
  const [users, setUsers] = useState([]);
  const [seatsFull, setSeatsFull] = useState(false);

  /**
   * Handles grabbing all the org details to populate the page
   */
  async function getOrgData() {
    let token = await getToken({ template: "protean-default" });

    const { data, error } = await getOrganizationDetails(token, organizationId);

    if (error != null) {
      if (error.status > 399 && error.status < 500) {
        window.open("/", "_self");
      }
    } else {
      // check if org has reached their plans limit
      setSeatsFull(
        data.organization.seats_active >= data.organization.seats_limit
      );

      // console.log(JSON.stringify(data, null, 2));
      if (typeof data.organization.seats_limit !== "number")
        data.organization.seats_limit = Number(data.organization.seats_limit);
      if (data.organization.seats_active !== "number")
        data.organization.seats_active = Number(data.organization.seats_active);
      if (data.organization.projects_limit !== "number")
        data.organization.projects_limit = Number(data.organization.projects_limit);
      if (data.organization.projects_active !== "number")
        data.organization.projects_active = Number(data.organization.projects_active);

      setOrg(data.organization);
      setOwner(data.owner);
      setUsers(data.users);
    }
  }

  useEffect(() => {
    getOrgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles submitting the email addresses for member invites
   */
  async function handleInviteMembers(event) {
    event.preventDefault();

    let token = await getToken({ template: "protean-default" });
    let addresses = event.target.emailAddresses.value.replace(/\s/g, "");

    if (addresses.length < 1) {
      return;
    }

    if (addresses.indexOf(",") > -1) {
      addresses = addresses.split(",");
    } else {
      addresses = [addresses];
    }

    let resp = await sendOrganizationInvite(token, organizationId, addresses);
    if (!resp.error) {
      event.target.emailAddresses.value = "";
    }
    // console.log("Response: " + JSON.stringify(resp));
  }

  return (
    <Container>
      <Row style={{ marginBottom: "4rem" }}>
        {/**
           "id",                         [x]
           "name",                       [x]
           "billing_cycle",              [remove]
           "rate_per_seat",              [remove]
           "free_trial_used":1,          [remove]
           "free_trial_in_progress",     [remove]
           "created_at",                 []
           "modified_at",                [remove]
           "deleted_at",                 [remove]
           "payment_processor_cust_id",  [remove]
           "billing_tier",               []
           "seats_limit",                []
           "seats_active",               []
           "projects_limit",             []
           "projects_active",            []
           "owner_user_id",              []
           "role"                        []
            */}

        <Card style={{ width: "28rem", height: "17rem", marginRight: "4rem" }}>
          <Card.Body>
            <Card.Title>{org.name}</Card.Title>
            <hr />

            <label>ID</label>
            <pre>
              {org.id} <CopyToClipboardButton data={org.id} />
            </pre>

            <label>Owner</label>
            <pre>
              {owner.first_name} {owner.last_name}
            </pre>

            <label>Created at</label>
            <pre>{org.created_at}</pre>
          </Card.Body>
        </Card>

        <Card style={{ width: "28rem", height: "17rem" }}>
          <Card.Body>
            <Card.Title>Billing</Card.Title>
            <hr />

            <label>Tier</label>
            <pre>{org.billing_tier}</pre>

            <label>Seats Active/Seats Limit</label>
            <pre>
              {org.seats_active}/{org.seats_limit}
            </pre>

            <label>Projects Active/Projects Limit</label>
            <pre>
              {org.projects_active}/{org.projects_limit}
            </pre>
          </Card.Body>
        </Card>
      </Row>

      <Row style={{ paddingBottom: "4rem" }}>
        <Card style={{ width: "60rem", height: "17rem", overflow: "scroll" }}>
          <Card.Body>
            <Card.Title>Members</Card.Title>

            <Table responsive hover className="members-table">
              <thead>
                <tr>
                  <th>id</th>
                  <th>role</th>
                  <th>name</th>
                  <th>email</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td key="id">{user.id}</td>
                    <td key="role">{user.role}</td>
                    <td key="name">
                      {user.first_name ?? ""} {user.last_name ?? ""}
                    </td>
                    <td key="email">{user.email ?? ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Row>

      <Row style={{ paddingBottom: "1rem" }}>
        <Card style={{ width: "60rem", height: "12rem", overflow: "scroll" }}>
          <Card.Body>
            <Card.Title>Invite Member</Card.Title>
            <Form noValidate onSubmit={handleInviteMembers}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  disabled={seatsFull}
                  type="email"
                  placeholder={
                    seatsFull
                      ? "Enter email (number of seats for your plan has been reached)"
                      : "Enter email"
                  }
                  name="emailAddresses"
                />
                <Form.Text className="text-muted">
                  You can enter multiple emails seperated by commas:{" "}
                  <i>matt@protean-labs.io, mark@protean-labs.io</i>
                </Form.Text>
              </Form.Group>

              <Button
                disabled={seatsFull}
                variant="outline-dark"
                className="rounded-1"
                type="submit"
              >
                Invite
              </Button>

              {seatsFull ? (
                <Button
                  variant="outline-dark"
                  className="rounded-1"
                  href="/dashboard/settings/billing"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Billing
                </Button>
              ) : (
                <span></span>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default OrganizationDetail;
