import React, { useEffect, useState } from "react";
import { Card, Row, Container } from "react-bootstrap";
import { getDashboardHomeInfo } from "../utils/apiRequests";
import { useAuth } from "@clerk/clerk-react";
import "./DashboardHome.css"

function DashboardHome() {
  const [organizations, setOrganizations] = useState([])
  const [projects, setProjects] = useState([])
  const [recentScans, setRecentScans] = useState([])
  const [todos, setTodos] = useState([])
  const { getToken } = useAuth()

  async function dashboardHomeInfo() {
    let token = await getToken({ template: 'protean-default' });

    const { data, error } = await getDashboardHomeInfo(token)

    if (error != null) {
      // TODO: need better error handling
      // console.log('[ERROR] Message: ')
      // console.error(error)
    } else {
      setOrganizations(data.organizations)
      setProjects(data.projects)
      setRecentScans(data.recent_scans)
      setTodos(data.todos)

      // console.log(`dashboard info: orgs: ${data.organizations}`)
      // console.log(`dashboard info: projs: ${data.projects}`)
      // console.log(`dashboard info: todos: ${data.todos}`)
      // console.log(`dashboard info: scans: ${data.recent_scans}`)
    }
  }

  useEffect(() => {
    dashboardHomeInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <h3>Dashboard Home</h3>

      <Row>
        {todos.length > 0 ? (
          <Card style={{ width: '20rem', height: '20rem', marginBottom: '30px', marginRight: '30px' }}>
            <Card.Title style={{paddingTop: "20px", paddingLeft: "15px"}}>✨ Getting Started</Card.Title>
            <Card.Body style={{ overflowY: "scroll" }}>
              <Card.Text>
                {todos.map(todo => (
                  <div dangerouslySetInnerHTML={{__html: todo}} />
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          <div> </div>
        )}

        <Card style={{ width: '20rem', height: '20rem', marginBottom: '30px', marginRight: '30px' }}>
          <Card.Title style={{paddingTop: "20px", paddingLeft: "15px"}}>Your Organizations</Card.Title>
          <Card.Body style={{ overflowY: "scroll" }}>
            <Card.Text>
                {organizations.length > 0 ? organizations.map(org => (
                <div key={org.id}>
                  <pre><a className="protean-link" href={`/dashboard/settings/organizations/${org.id}`}>{org.name}</a></pre>
                </div>
              )) : <div>No organizations</div>}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card style={{ width: '20rem', height: '20rem', marginBottom: '30px', marginRight: '30px' }}>
          <Card.Title style={{paddingTop: "20px", paddingLeft: "15px"}}>Your Projects</Card.Title>
          <Card.Body style={{ overflowY: "scroll" }}>
            <Card.Text>
              {projects.length > 0 ? projects.map(project => (
                <div key={project}>
                  <pre>{project}</pre>
                </div>
              )) : <div>No projects</div>}
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>

      <Row>
        <Card style={{ width: '64rem', height: '20rem', marginBottom: '30px', marginRight: '30px', overflow: "scroll" }}>
          <Card.Title style={{paddingTop: "20px", paddingLeft: "10px"}}>Recent Scans</Card.Title>
          <Card.Body style={{ overflowY: "scroll" }}>
            <Card.Text>
                {recentScans ?
                    <table style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Organization</th>
                            <th>Project</th>
                            <th>Critical</th>
                            <th>High</th>
                            <th>Med</th>
                            <th>Low</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recentScans.map(scan => (
                            // console.log(scan)
                            <tr key={scan.id}>
                                <td><a className="protean-link" href={`/dashboard/scans/${scan.id}`}>{scan.id}</a></td>
                                <td>{scan.organization_name}</td>
                                <td>{scan.project_name}</td>
                                <td>{scan.result.status.critical}</td>
                                <td>{scan.result.status.high}</td>
                                <td>{scan.result.status.medium}</td>
                                <td>{scan.result.status.low}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                : <div>No recent scans found</div>}
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  )
}

export default DashboardHome;
