import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Dropdown, Card, Row, Container, Toast, ToastContainer } from "react-bootstrap";
import { getOrganizationsAdmin, getValidBillingTiers } from "../utils/apiRequests";
import StripeCheckoutModal from "../components/StripeCheckoutModal";
import { useAuth } from "@clerk/clerk-react";

function SettingsBilling() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState('Select an Organization');
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [billingTiers, setBillingTiers] = useState([]);
  const [selectedOrganizationBillingTier, setSelectedOrganizationBillingTier] = useState('');
  const [showStripeCheckoutModal, setShowStripeCheckoutModal] = useState(false);

  const { getToken } = useAuth()

  /**
   * setSelectedOrg
   */
  async function setSelectedOrg(org) {
    setSelectedOrganizationName(org.name)
    setSelectedOrganizationId(org.id)
  }

  /**
   * getOrganizationsFromApi
   */
  async function getAdminOrganizationsFromApi() {
    let token = await getToken({ template: 'protean-default' });
    const { data, error } = await getOrganizationsAdmin(token);

    if (error != null) {
      // console.log("There was an error during getOrganizationsFromApi")
      // console.error(error)
    } else {
      // console.log("getOrganizationsAdmin result: " + JSON.stringify(data))
      setOrganizations([...data])
    }
  }

  /**
   * getBillingTiersFromApi
   * Example response:
   * {
   *   "current_tier": "ProteanLabs::Tier::Free",
   *   "available_tiers": [
   *     {
   *       "price": "price_1QzsAbCyZ2ZWqaM8IbrQV7DJ",
   *       "name": "Enterprise"
   *     },
   *     {
   *       "price": "price_1QzsARCyZ2ZWqaM80I2kA7CQ",
   *       "name": "Business"
   *     },
   *     ...
   *   ]
   * }
   */
  async function getBillingTiersFromApi() {
    let token = await getToken({ template: 'protean-default' });
    const { data, error } = await getValidBillingTiers(token, selectedOrganizationId);

    if (error != null) {
      // console.error(error)
    } else if (data != null) {
      setBillingTiers([...data.available_tiers])
    }
    // data is null so dont do anything
  }

  useEffect(() => {
    getAdminOrganizationsFromApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedOrganizationId) {
      getBillingTiersFromApi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId])

  const OrganizationSelectorDropdownItems = () => {
    return (
      <>
      <StripeCheckoutModal
        show={showStripeCheckoutModal}
        onHide={() => setShowStripeCheckoutModal(false)}
        onExit={() => setShowStripeCheckoutModal(false)}
        organizationId={selectedOrganizationId}
        billingTier={selectedOrganizationBillingTier}
      />

      <Dropdown>
        <Dropdown.Toggle
          variant="outline-dark"
          id="dropdown-basic"
          className="rounded-1"
        >
            { selectedOrganizationName }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { organizations.map(org =>
              <Dropdown.Item
                eventKey={org.name}
                key={org.name}
                onClick={() => setSelectedOrg(org)}
              >
                {org.name}
                </Dropdown.Item>
            ) }
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }

  return (
    <Container>
      <ToastContainer
        className="p-8"
        position="top-center"
        style={{ marginTop: '1rem' }}
      >
        <Toast
          show={searchParams.get("success")}
          onClose={() => {
            searchParams.delete("success");
            setSearchParams(searchParams);
          }}
        >
          <Toast.Header closeButton={true}>
            <strong className="me-auto">Success!</strong>
          </Toast.Header>
          <Toast.Body>
            Please allow up to 60 seconds for your changes to take effect.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <h3>Billing</h3>

      <Row>

        <Card style={{ width: '20rem', height: '20rem' }}>
          <Card.Body>
            <Card.Title>View Plans</Card.Title>
            <Card.Text>
              To see our available plans check out our Pricing page!
            </Card.Text>
            <Button variant="outline-dark" href="/pricing">Pricing</Button>
          </Card.Body>
        </Card>

        <br />

        <Card style={{ width: '20rem', height: '20rem' }}>
          <Card.Body>
            <Card.Title>
              Manage Plans
            </Card.Title>
            <Card.Text>
              To manage your plan please choose which organization you'd like to manage
            </Card.Text>

            <OrganizationSelectorDropdownItems />
          </Card.Body>
        </Card>

      </Row>

      { selectedOrganizationId ? (
        <Row>
          <Card style={{ minWidth: '30rem', minHeight: '20rem', marginTop: '20px' }}>
            <Card.Body>
              <Card.Title>
                Update {selectedOrganizationName} Subscription
              </Card.Title>
              <Row style={{ overflow: 'scroll' }}>
                  { billingTiers.map((tier) => (
                      <div
                        key={tier.price}
                        className="form-check"
                        style={{
                          width: '130px',
                          height: '130px',
                          marginLeft: '10px',
                          marginBottom: '10px',
                          border: tier.price === selectedOrganizationBillingTier ? '2px solid #9b5ff5' : '1px solid #343a40',
                          borderRadius: '4px',
                          padding: '5px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                        onClick={() => {
                          setSelectedOrganizationBillingTier(tier.price);
                        }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor={`tier-${tier.price}`}
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            textAlign: 'center'
                          }}
                        >
                          {tier.name}
                        </label>
                      </div>
                  ))}
            </Row>
            <Button
              variant="outline-dark"
              style={{
                marginTop: '10px'
              }}
              onClick={ () => setShowStripeCheckoutModal(true) }
            >
              Purchase
            </Button>
            </Card.Body>
          </Card>
        </Row>
      ) : null}

    </Container>
  )
}

export default SettingsBilling
