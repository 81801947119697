import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CopyToClipboardButton from "../components/CopyToClipboardButton";

function ContactUs() {
  return (
    <div
      style={{
        paddingTop: "70px",
        paddingBottom: "100px",
      }}
    >
      <Container>
        <Row>
          <Col>
            <h1>Contact Us</h1>

            <br />

            <h4>Enterprise security, human support.</h4>
            <p>
              We love feedback and suggestions, feel free to drop us a line anytime.
              <br />
              Don't hesitate to reach out to us if you have any questions or concerns.
            </p>
          </Col>
        </Row>

        <br />
        <br />

        <Row>
          <Col>
            <h4>Support</h4>
            <sub><b>support@protean-labs.io</b><CopyToClipboardButton data="support@protean-labs.io" /></sub>

            <br />
            <br />

            <h4>Sales</h4>
            <sub><b>sales@protean-labs.io</b><CopyToClipboardButton data="sales@protean-labs.io" /></sub>

            <br />
            <br />

            <h4>Investors</h4>
            <sub><b>investors@protean-labs.io</b><CopyToClipboardButton data="investors@protean-labs.io" /></sub>

            <br />
            <br />

            <h4>Legal</h4>
            <sub><b>legal@protean-labs.io</b><CopyToClipboardButton data="legal@protean-labs.io" /></sub>
          </Col>
          <Col>
            <h4>Discord</h4>
            <p>Join our <a href="https://discord.gg/dVT57vBufM"
              style={{ textDecoration: "none", color: "#5661ea" }}>Discord</a> server!</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;
