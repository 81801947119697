import React from "react";

function TermsOfService() {
  return (
    <div
      style={{
          textAlign: 'left',
          paddingTop: '100px',
          paddingBottom: '100px',
          paddingLeft: '20%',
          paddingRight: '20%',
      }}
    >

      <h1>Terms of Service</h1>
      <sub>Last updated: <b>Feb 2024</b></sub>
      <br />
      <br />
      <br />

      <h2>Terms</h2>
      <p>By accessing this Website, accessible from https://protean-labs.io, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>
      <p>Logo rights. The customer agrees that until either party terminates these Terms, the Company may use the customer’s name and/or logo on the Company’s websites and in its customer lists and marketing materials, provided that any such use complies with any usage parameters provided to the Company in writing, and is displayed in a manner substantially similar to Protean Labs’s display of other customer names and logos.</p>
      <br />

      <h2>Use License</h2>
      <p>Permission is granted to temporarily download one copy of the materials on Protean Labs, LLC's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
      <ul>
        <li>modify or copy the materials;</li>
        <li>use the materials for any commercial purpose or for any public display;</li>
        <li>attempt to reverse engineer any software contained on Protean Labs, LLC's Website;</li>
        <li>remove any copyright or other proprietary notations from the materials; or</li>
        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
      </ul>
      <p>This will let Protean Labs, LLC to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>
      <br />

      <h2>Disclaimer</h2>
      <p>All the materials on Protean Labs, LLC's Website are provided "as is". Protean Labs, LLC makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Protean Labs, LLC does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
      <br />

      <h2>Limitations</h2>
      <p>Protean Labs, LLC or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Protean Labs, LLC's Website, even if Protean Labs, LLC or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
      <br />

      <h2>Revisions and Errata</h2>
      <p>The materials appearing on Protean Labs, LLC's Website may include technical, typographical, or photographic errors. Protean Labs, LLC will not promise that any of the materials in this Website are accurate, complete, or current. Protean Labs, LLC may change the materials contained on its Website at any time without notice. Protean Labs, LLC does not make any commitment to update the materials.</p>
      <br />

      <h2>Links</h2>
      <p>Protean Labs, LLC has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Protean Labs, LLC of the site. The use of any linked website is at the user's own risk.</p>
      <br />

      <h2>Site Terms of Use Modifications</h2>
      <p>Protean Labs, LLC may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
      <br />

      <h2>Your Privacy</h2>
      <p>Please read our <a href="/privacy-policy">Privacy Policy.</a></p>
      <br />

      <h2>Governing Law</h2>
      <p>Any claim related to Protean Labs, LLC's Website shall be governed by the laws of us without regards to its conflict of law provisions.</p>
      <br />
    </div>
  )
}

export default TermsOfService;
