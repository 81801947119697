import React, { useState, useEffect } from "react";
import { Toast, ToastContainer, Button, Row, Col } from "react-bootstrap";

const CookieToast = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasCookieConsent = localStorage.getItem("cookieConsent");
    if (!hasCookieConsent) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("cookieConsent", "true");
    setShow(false);
  };

  return (
    <ToastContainer
      bg="dark"
      position="bottom-end"
      className="p-3 position-fixed"
    >
      <Toast
        bg="dark"
        show={show}
        onClose={handleClose}
        style={{ width: "600px" }}
      >
        <Toast.Body>
          <b>
            <u style={{paddingLeft: '18px'}}>Cookie Notice</u>
          </b>
          <Row>
            <Col className="col-9" style={{paddingLeft: '30px'}}>
              <Row>
                <p>We use cookies to enhance your experience on our website. By
                continuing to use our site, you agree to our use of cookies.</p>
              </Row>
              <Row>
                <p>For more information please see our <a href="/cookie-policy" style={{color: 'white'}}>Cookie Policy</a></p>
              </Row>
            </Col>
            <Col className="col-1">
              <Button
                variant="outline-light"
                className="ms-3"
                onClick={handleClose}
              >
                Agree
              </Button>
            </Col>
          </Row>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default CookieToast;
