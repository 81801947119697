import { useAuth } from "@clerk/clerk-react";
import { useState, useContext } from "react";
import {
  Button,
  Form,
  Modal
} from "react-bootstrap"
import { renameProject } from "../utils/apiRequests"
import APIErrorModal from "./APIErrorModal";
import ProjectContext from "../contexts/ProjectContext";

function RenameProjectModal(props) {
  const [projectName, setProjectName] = useState('')
  const projectContext = useContext(ProjectContext);
  const [errorModalShow, setErrorModalShow] = useState(false)
  const [errorModalMsg, setErrorModalMsg] = useState('')

  const {getToken} = useAuth()

  // Reset form when modal closes
    const handleClose = () => {
      setProjectName('')
      props.onHide()
    }

  // Form submit button handler
  async function formSubmit(newName) {
    if (newName === projectContext.selectedProject?.name) {
        handleClose()
      return
    }

    const token = await getToken({template: "protean-default"})
    let projectId = projectContext.selectedProject?.id;

    // eslint-disable-next-line
    const {data, error} = await renameProject(token, projectId, newName)
    if (error != null) {
      // Modal
      // console.log(error)
      setErrorModalMsg(error.message || 'Your request could not be processed')
      setErrorModalShow(true)
    } else {
        handleClose()
    }
    // setSubmitted(false)
  }

  return (
    <>
      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onExit={() => setProjectName('') }
          onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
            <Modal.Title>
                Rename {projectContext.selectedProject?.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group>
              <Form.Label>Rename {projectContext.selectedProject?.name} to</Form.Label>
              <Form.Control
                type="text"
                placeholder="Users service"
                onChange={v => setProjectName(v.target.value)}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={ () => formSubmit(projectName) }
              disabled={projectName.length < 5}
              type="submit"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RenameProjectModal;
