import { Container, Image } from "react-bootstrap"
import bluelagoon from '../images/bluelagoon.avif';

function NotFound () {
  return (
    <Container
      style={{
        minHeight: '90vh',
        textAlign: 'center',
      }}
    >
      <h1
          style={{
            paddingTop: '25px',
            paddingBottom: '25px',
          }}
      >
        Page not found
      </h1>

      <Image
        src={bluelagoon}
        alt="404 Page Not Found"
        style={{
          pointerEvents: 'none',
          paddingTop: '5px',
          paddingBottom: '50px',
          maxWidth: '98%',
          maxHeight: '50%',
        }}
      />
    </Container>
  )
}

export default NotFound
